import React from 'react';
import spinner from 'assets/images/loader.gif';

const FullPageLoader = () => {
    return (
        // <div
        //     className="fp-container"
        //     style={{
        //         position: 'fixed',
        //         top: '0',
        //         left: '0',
        //         background: '#000',
        //         opacity: '0.5',
        //         zIndex: '2000',
        //         height: '100%',
        //         width: '100%'
        //     }}
        // >
        //     <img src={spinner} className="fp-loader" alt="Loading" />
        // </div>
        <div id="loader" class="loading">
            <span class="loader"></span>
        </div>
    );
};

export default FullPageLoader;
