import HeaderLayout from 'layout/HeaderLayout';
import React from 'react';

const Header = () => {
    return (
        <>
            <HeaderLayout/>
            {/* <header className="app-header top-bar">
                <nav className="navbar navbar-expand-md">
                    <div className="container-fluid">
                        <a href="#!" className="nav-link sidebar-toggle mobile-toggle">
                            <span className="navbar-toggler-icon"></span>
                        </a>
                        <div className="navbar-header d-flex align-items-center justify-content-around">
                            <a className="navbar-brand" href="javascript:void(0)">
                                <img src={'/images/logo.png'} className="img-fluid logo" alt="logo" />
                            </a>
                        </div>

                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon" />
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <div className="navigation d-flex">
                                <ul className="navbar-nav nav-left">
                                    <li className="nav-item">
                                        <a href="#" className="nav-link sidebar-toggle">
                                            <i className="fa-solid fa-sliders" />
                                        </a>
                                    </li>
                                </ul>
                                <ul className="navbar-nav nav-right">
                                    <li className="nav-item">
                                        <a className="nav-link" href="#">
                                            <i className="fa-solid fa-envelope" />
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link search" href="#">
                                            <i className="fa-solid fa-magnifying-glass" />
                                        </a>
                                    </li>
                                    <li className="nav-item user-profile">
                                        <a href="#" className="nav-link">
                                            <img src={'/images/avtar/02.jpg'} alt="avtar-img" />
                                            <span className="bg-success user-status" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
            </header> */}
        </>
    );
};

export default Header;
