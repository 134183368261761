import MainLayout from 'layout/MainLayout';
import MinimalLayout from 'layout/MinimalLayout';
import HeaderLayout from 'layout/HeaderLayout';
import React from 'react';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

// project imports
import Loadable from 'ui-component/Loadable';
// import { Test } from 'views/admin-components/pages/Test';
import DashBoardLayout from 'layout/DashboardLayout';
import LandingTestPage from 'views/user-components/LandingTestPage';
import AssetAllocation from 'views/user-components/pages/dashboard/AssetAllocation';
// import Home from 'views/user-components/pages/dashboard/Home';

import AssetAllocationHome from 'views/user-components/pages/dashboard/AssetAllocationMain';
import ThankYouPage from 'views/user-components/pages/ThankYouPage';
import ProfileFinalStep from 'views/user-components/pages/signup-profile/ProfileFinalStep';

const Home = Loadable(lazy(() => import('views/user-components/pages/dashboard/Home')));
const EmployeeInfo = Loadable(lazy(() => import('views/user-components/pages/dashboard/EmployeeInfo')));
const MessageCenter = Loadable(lazy(() => import('views/user-components/pages/dashboard/MessageCenter')));
const ProfileSteps = Loadable(lazy(() => import('views/user-components/pages/signup-profile/ProfileSteps')));
const Personal = Loadable(lazy(() => import('views/user-components/pages/dashboard/Personal')));
const Goals = Loadable(lazy(() => import('views/user-components/pages/dashboard/Goals')));
const Accounts = Loadable(lazy(() => import('views/user-components/pages/dashboard/Accounts')));
const Assets = Loadable(lazy(() => import('views/user-components/pages/dashboard/Assets')));
const IncomeExpenses = Loadable(lazy(() => import('views/user-components/pages/dashboard/IncomeExpenses')));
const EstatePlanning = Loadable(lazy(() => import('views/user-components/pages/dashboard/EstatePlanning')));
const Fees = Loadable(lazy(() => import('views/user-components/pages/dashboard/Fees')));
const Test = Loadable(lazy(() => import('views/admin-components/pages/Test')));
const AssetAllocationAccounts = Loadable(lazy(() => import('views/user-components/pages/dashboard/AssetAllocationAccounts')));

const AccountSummary = Loadable(lazy(() => import('views/user-components/pages/dashboard/AccountSummary')));
const CashFlow = Loadable(lazy(() => import('views/user-components/pages/dashboard/CashFlow')));
const NetWorth = Loadable(lazy(() => import('views/user-components/pages/dashboard/NetWorth')));
const FundTransfer = Loadable(lazy(() => import('views/user-components/pages/dashboard/FundTransfer')));
const DataVault = Loadable(lazy(() => import('views/user-components/pages/dashboard/DataVault')));
const AssetDrift = Loadable(lazy(() => import('views/user-components/pages/dashboard/AssetDrift')));
const ProjectedCashFlow = Loadable(lazy(() => import('views/user-components/pages/dashboard/ProjectedCashFlow')));
const ProjectedNetWorth = Loadable(lazy(() => import('views/user-components/pages/dashboard/ProjectedNetWorth')));
const InsuranceCalculator = Loadable(lazy(() => import('views/user-components/pages/dashboard/InsuranceCalculator')));
const FinancialGoal = Loadable(lazy(() => import('views/user-components/pages/dashboard/FinancialGoal')));

const isUserLogin = localStorage.getItem('isUserLogin');
const userId = localStorage.getItem('user_id');
const partnerName = localStorage.getItem('partner_url');

const ClientRoutes = {
    path: '/',
    // THIS IS TEMP SOLUTION FOR SIGNUP FORM AFTER FIRST TIME LOGIN. FOR MORE CHECK NOTE BOOKS TO HOW TO SETUP.
    element: isUserLogin ? <DashBoardLayout /> : <Navigate to={`${partnerName}/signin`} />,
    children: [
        // todo: send it to main landing page in else condition.
        // { path: '/signup/profile/:userid', element: isUserLogin ? <ProfileSteps /> : <Navigate to={`/smartmoney/signin`} /> }, //NAVIGATE({ TO, REPLACE, STATE }
        { path: '/home', element: isUserLogin ? <Home /> : <Navigate to={`/smartmoney/signin`} /> },
        { path: '/employee_info', element: isUserLogin ? <EmployeeInfo /> : <Navigate to={`/smartmoney/signin`} /> },
        { path: '/personal', element: isUserLogin ? <Personal /> : <Navigate to={`/smartmoney/signin`} /> },
        { path: '/assetallocation', element: isUserLogin ? <AssetAllocation /> : <Navigate to={`/smartmoney/signin`} /> },
        { path: '/main', element: isUserLogin ? <AssetAllocationHome /> : <Navigate to={`/smartmoney/signin`} /> },
        { path: '/asset_allocation_accounts', element: isUserLogin ? <AssetAllocationAccounts /> : <Navigate to={`/smartmoney/signin`} /> },
        { path: '/goals', element: isUserLogin ? <Goals /> : <Navigate to={`/smartmoney/signin`} /> },
        { path: '/accounts', element: isUserLogin ? <Accounts /> : <Navigate to={`/smartmoney/signin`} /> },
        { path: '/assets', element: isUserLogin ? <Assets /> : <Navigate to={`/smartmoney/signin`} /> },
        { path: '/fees', element: isUserLogin ? <Fees /> : <Navigate to={`/smartmoney/signin`} /> },
        { path: '/fund_transfer', element: isUserLogin ? <FundTransfer /> : <Navigate to={`/smartmoney/signin`} /> },
        { path: '/income_expenses', element: isUserLogin ? <IncomeExpenses /> : <Navigate to={`/smartmoney/signin`} /> },
        { path: '/estate_planning', element: isUserLogin ? <EstatePlanning /> : <Navigate to={`/smartmoney/signin`} /> },
        // REPORT
        { path: '/account_summary', element: isUserLogin ? <AccountSummary /> : <Navigate to={`/smartmoney/signin`} /> },
        { path: '/cashflow', element: isUserLogin ? <CashFlow /> : <Navigate to={`/smartmoney/signin`} /> },
        { path: '/networth', element: isUserLogin ? <NetWorth /> : <Navigate to={`/smartmoney/signin`} /> },
        { path: '/data_vault', element: isUserLogin ? <DataVault /> : <Navigate to={`/smartmoney/signin`} /> },
        { path: '/message_center', element: isUserLogin ? <MessageCenter /> : <Navigate to={`/smartmoney/signin`} /> },
        { path: '/asset_drift', element: isUserLogin ? <AssetDrift /> : <Navigate to={`/smartmoney/signin`} /> },
        // FINANCIAL PLAN
        { path: '/projected_cash_flow', element: isUserLogin ? <ProjectedCashFlow /> : <Navigate to={`/smartmoney/signin`} /> },
        { path: '/projected_net_worth', element: isUserLogin ? <ProjectedNetWorth /> : <Navigate to={`/smartmoney/signin`} /> },
        { path: '/insurance_calculator', element: isUserLogin ? <InsuranceCalculator /> : <Navigate to={`/smartmoney/signin`} /> },
        { path: '/financial_goals', element: isUserLogin ? <FinancialGoal /> : <Navigate to={`/smartmoney/signin`} /> },

        { path: '/thanks', element: isUserLogin ? <ThankYouPage /> : <Navigate to={`/smartmoney/signin`} /> }
    ]
};

export default ClientRoutes;
