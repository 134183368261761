import axiosInstance from 'custom-axios';
import { numberFormat } from 'hooks/useNumberFormat';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { toast } from 'react-toastify';
import RadialBarChart from 'views/charts/RadialBarChart';
import { colorArray } from 'store/constant';
import DonutChart from 'views/charts/DonutChart';

const AssetAllocation = (props) => {
    const userId = localStorage.getItem('user_id');
    var sizeOfGraphSection = props.size;
    const [totalProfitTotal, setTotalProfitTotal] = useState();
    const [chartAccountValue, setChartAccountValue] = useState([]);
    const [chartTitleName, setChartTitleName] = useState([]);

    const [totalAssetTotal, setTotalAssetTotal] = useState();
    const [chartAssetValue, setChartAssetValue] = useState([]);
    const [chartAssetTitle, setAssetChartTitle] = useState([]);

    const [totalSymbolTotal, setTotalSymbolTotal] = useState();
    const [chartSymbolValue, setChartSymbolValue] = useState([]);
    const [chartSymbolTitle, setSymbolChartTitle] = useState([]);
    const [dataLength, setDataLength] = useState([]);

    const [graphColorArray, setGraphColorArray] = useState([]);

    const fetchAccountChartValues = async () => {
        try {
            var accountTitleName = [];
            var accountValue = [];
            const response = await axiosInstance.get(`api/getAccountChartValues/${userId}/account_chart_values`);
            // setChartFullData(response.data)
            setDataLength(response.data.accounts.length);
            setTotalProfitTotal(response.data.total_profit);

            for (let i = 0; i < response.data.accounts.length; i++) {
                accountTitleName.push(response.data.accounts[i].account);
                accountValue.push(response.data.accounts[i].accountPercentage);
            }
            setChartTitleName(accountTitleName);
            setChartAccountValue(accountValue);
        } catch (error) {
            toast.error(error.message);
        }
    };

    const getAccountChart = async () => {
        // try {
        //     const response = await axiosInstance.get(`api/getAssetChartValue/${userId}/asset_chart_value`);
        //     // console.log(response)
        // } catch (error) {
        //     toast.error(error.message)
        // }
    };
    const getAccountTypeChart = async () => {};

    const getAssetClassesChart = async () => {
        try {
            var assetTitleName = [];
            var assetValue = [];
            const response = await axiosInstance.get(`api/getAssetChartValue/${userId}/asset_chart_value`);

            setTotalAssetTotal(response.data.assetData.totalProfitAssetTotal);
            for (let i = 0; i < response.data.assetData.assets.length; i++) {
                assetTitleName.push(response.data.assetData.assets[i].asset);
                assetValue.push(Number(response.data.assetData.assets[i].valuePercentage));
            }

            setAssetChartTitle(assetTitleName);
            setChartAssetValue(assetValue);
        } catch (error) {
            toast.error(error.message);
        }
    };
    const getSecuritiesChart = async () => {
        try {
            var accountTitleName = [];
            var accountValue = [];

            const response = await axiosInstance.get(`api/getSymbolChartValue/${userId}/symbol_chart_value`);

            setTotalSymbolTotal(response.data.symbolData.totalprofitSymbolTotal);

            for (let i = 0; i < response.data.symbolData.symbols.length; i++) {
                accountTitleName.push(response.data.symbolData.symbols[i].symbol);
                accountValue.push(Number(response.data.symbolData.symbols[i].valuePercentage));
            }

            setSymbolChartTitle(accountTitleName);
            setChartSymbolValue(accountValue);
        } catch (error) {
            toast.error(error.message);
        }
    };

    useEffect(() => {
        fetchAccountChartValues(); // for assetallocation
    }, []);

    return (
        <>
            <div className={`${sizeOfGraphSection ? 'col-lg-12' : 'col-lg-6'} col-md-12 mb-4`}>
                <div className={`card ${!sizeOfGraphSection ? 'asset-allocation-graph' : ' '}`}>
                    <div className="card-header d-xxl-flex justify-content-between">
                        <h6 className="card-title">Assent Allocation</h6>
                    </div>
                    {dataLength > 0 ? (
                        <>
                            <div className="account-tab mt-4">
                                <nav>
                                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                        <button
                                            onClick={() => getAccountChart()}
                                            className="nav-link active"
                                            id="account-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#account"
                                            type="button"
                                            role="tab"
                                            aria-controls="nav-home"
                                            aria-selected="true"
                                        >
                                            Account
                                        </button>
                                        <button
                                            onClick={() => getAccountTypeChart()}
                                            className="nav-link"
                                            id="account-type-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#account-type"
                                            type="button"
                                            role="tab"
                                            aria-controls="account-type"
                                            aria-selected="false"
                                        >
                                            Account Type
                                        </button>
                                        <button
                                            onClick={() => getAssetClassesChart()}
                                            className="nav-link"
                                            id="account-classic-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#account-classic"
                                            type="button"
                                            role="tab"
                                            aria-controls="account-classic"
                                            aria-selected="false"
                                        >
                                            Asset Class
                                        </button>
                                        <button
                                            onClick={() => getSecuritiesChart()}
                                            className="nav-link"
                                            id="nav-security-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#nav-security"
                                            type="button"
                                            role="tab"
                                            aria-controls="nav-security"
                                            aria-selected="false"
                                        >
                                            Security
                                        </button>
                                    </div>
                                </nav>
                            </div>

                            <div className="card-body card-body-height">
                                <div className="tab-content" id="nav-tabContent">
                                    <div className="tab-pane fade show active" id="account" role="tabpanel" aria-labelledby="account-tab">
                                        <div className="row align-item-center">
                                            <div className="col-lg-12">
                                                <RadialBarChart
                                                    height={400}
                                                    series={chartAccountValue}
                                                    total={totalProfitTotal}
                                                    titleName={chartTitleName}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {/*===========================ACCOUNT TYPE CHART===========================*/}
                                    <div className="tab-pane fade" id="account-type" role="tabpanel" aria-labelledby="account-type-tab">
                                        <RadialBarChart
                                            height={450}
                                            series={chartAccountValue}
                                            total={totalProfitTotal}
                                            titleName={chartTitleName}
                                        />
                                    </div>
                                    {/*=========================ASSET CLASS CHART================================*/}
                                    <div
                                        className="tab-pane fade"
                                        id="account-classic"
                                        role="tabpanel"
                                        aria-labelledby="account-classic-tab"
                                    >
                                        <DonutChart seriesValue={chartAssetValue} labelTitle={chartAssetTitle} total={totalProfitTotal} />
                                    </div>

                                    {/*=====================ASSET SECURITY CHART====================*/}
                                    <div className="tab-pane fade" id="nav-security" role="tabpanel" aria-labelledby="nav-security-tab">
                                        <DonutChart seriesValue={chartSymbolValue} labelTitle={chartSymbolTitle} total={totalProfitTotal} />
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="text-center">No graph data available</div>
                    )}
                </div>
            </div>
        </>
    );
};

export default AssetAllocation;
