// assets
import { IconLetterR } from '@tabler/icons';

// constant
const icons = {
    IconLetterR
};

const ReblancingMenuItem = {
    id: 'reblancers',
    // title: 'Pages',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'reblancers',
            title: 'Reblancing',
            type: 'collapse',
            icon: icons.IconLetterR,
            url: '/admin/rebalancers',
            breadcrumbs: false,
            children: [
                {
                    id: 'reblancer',
                    title: 'Rebalancer',
                    type: 'item',
                    url: '/rebalancers',
                    target: true
                },
                {
                    id: 'tradingqueue',
                    title: 'Trading Queue',
                    type: 'item',
                    url: '/trading_queue',
                    target: true
                },
                {
                    id: 'pendingtrade',
                    title: 'Pending Trade',
                    type: 'item',
                    url: '/pending_transaction',
                    target: true
                },
                {
                    id: 'pendingwithdrawal',
                    title: 'Pending Withdrawals',
                    type: 'item',
                    url: '/pending_withdrawal',
                    target: true
                },
                {
                    id: 'reblancingrules',
                    title: 'Reblancers',
                    type: 'item',
                    url: '/rebalancing_rules',
                    target: true
                }
            ]
        }
    ]
};

export default ReblancingMenuItem;
