import axiosInstance from 'custom-axios';
import { numberFormat, numberPerCentageFormat } from 'hooks/useNumberFormat';
import { elementType } from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { toast } from 'react-toastify';
import AssetAllocation from './AssetAllocation';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
// import { LocalizationProvider } from '@mui/x-date-pickers-pro';
// import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
// import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { colorArray } from 'store/constant';
import { useFullPageLoader } from 'hooks/useFullPageLoader';
const AssetAllocationHome = () => {
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const userId = localStorage.getItem('user_id');
    const [accountSummaryData, setAccountSummaryData] = useState();
    const [openDateRange, setOpenDateRange] = React.useState(false);
    const [dateValue, setDateValue] = React.useState([null, null]);
    const [graphData, setGraphData] = useState({
        graph_data: [],
        dates: []
    });

    const fetchAssetOverviewDetails = async () => {
        showLoader();
        try {
            const response = await axiosInstance.get(`api/getAssetOverviewDetails/${userId}/asset_overview_details`);
            if (response.status == 'success') {
                setAccountSummaryData(response.data);
            }
            hideLoader();
        } catch (error) {
            toast.error(error.message);
        }
    };

    const fetchGraphValue = async (graphType) => {
        showLoader();
        try {
            const response = await axiosInstance.get(`api/getGraphValues/${userId}/${graphType}/graph_value`);
            setGraphData(response.data);
            hideLoader();
        } catch (error) {
            toast.error(error.message);
        }
    };

    const handleOpenDatePicker = () => {
        setOpenDateRange(true);
    };

    const handelClickGraphType = async (graphType) => {
        fetchGraphValue(graphType);
    };

    const CustomDateComponent = () => {
        return (
            <>
                {openDateRange
                    ? 'OPEn'
                    : // <LocalizationProvider dateAdapter={AdapterDayjs} localeText={{ start: 'Check-in', end: 'Check-out' }}>
                      //     <DateRangePicker
                      //         value={dateValue}
                      //         onChange={(newValue) => {
                      //             setDateValue(newValue);
                      //         }}
                      //         renderInput={(startProps, endProps) => (
                      //             <React.Fragment>
                      //                 <TextField {...startProps} />
                      //                 <Box sx={{ mx: 2 }}> to </Box>
                      //                 <TextField {...endProps} />
                      //             </React.Fragment>
                      //         )}
                      //     />
                      // </LocalizationProvider>
                      undefined}
            </>
        );
    };
    // console.log(graphData.graph_data, graphData.dates);
    const defaultGraph1 = {
        series: graphData.graph_data,
        // series: [
        //     {
        //         name: 'account 1',
        //         data: [252742.01, 255960.96, 255960.96]
        //     },
        //     {
        //         name: 'account 2',
        //         data: [223159.36, 222632.59, 221497.2]
        //     }
        // ],

        options: {
            colors: colorArray,
            chart: {
                height: 350,
                width: 500,
                type: 'area'
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                type: 'datetime',

                categories: graphData.dates
            },
            tooltip: {
                x: {
                    format: 'MM/dd/yy'
                }
            },

            title: {
                // text: "Solar Data",
                style: {
                    fontSize: '24px',
                    fontWeight: 'bold',
                    fontFamily: undefined,
                    color: '#263238'
                }
            },
            responsive: [
                {
                    breakpoint: 500,
                    options: {
                        plotOptions: {
                            bar: {
                                horizontal: false
                            }
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }
            ]
        }
    };

    useEffect(() => {
        fetchGraphValue(1); //userId, GraphType
        fetchAssetOverviewDetails();
        /*  async function fetchAllApi() {
            try {
                await fetchAssetOverviewDetails()();
            } catch (error) {
                toast.error(error.message);
                // setIsAllApiResponseGet(false);
            } finally {
                // setLoader(false);
            }
        }
        fetchAllApi(); */
    }, []);

    return (
        <>
            <div className="row">
                {/* <!-- table Start--> */}
                <div className="col-12 col-lg-12">
                    <div className="card card-statistic border-none">
                        <div className="card-header d-flex align-item-center table-border">
                            <div className="card-heading">
                                <h6 className="card-title">Account Summary</h6>
                            </div>
                            <div className="download-icon ms-auto">
                                <a href="#">
                                    <img className="img-fluid" src={'/images/download.png'} alt="download-image" />
                                </a>
                            </div>
                        </div>
                        <div className="card-body p-4">
                            <div className="table-responsive">
                                <table className="table table-striped mb-0">
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">Account Number</th>
                                            <th scope="col">Account Type</th>
                                            <th scope="col">Book Value</th>
                                            <th scope="col">Change</th>
                                            <th scope="col">Total Account Value</th>
                                            <th className="text-center" scope="col">
                                                Money-Weighted Performance
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody style={{ borderTop: 'none' }}>
                                        {accountSummaryData?.map((item) => {
                                            var bookValueTextColor = '';

                                            if (item.change > 0) {
                                                bookValueTextColor = 'green-text';
                                            } else if (item.change < 0) {
                                                bookValueTextColor = 'red-text';
                                            }

                                            const redTextClass = item.closing_date ? 'red-text' : '';

                                            return (
                                                <>
                                                    <tr>
                                                        <td>
                                                            {item.account_number}
                                                            <div className={redTextClass.toString()}>
                                                                <small>{item.closing_date}</small>
                                                            </div>
                                                        </td>
                                                        <td>{item.account_type}</td>
                                                        <td>{numberFormat(item.book_value)}</td>
                                                        <td className={bookValueTextColor.toString()}>{numberFormat(item.change)}</td>
                                                        <td className="text-center">{numberFormat(item.market_value)}</td>
                                                        <td className="text-center">{numberPerCentageFormat(item.mwrr)}%</td>
                                                    </tr>
                                                </>
                                            );
                                        })}

                                        {/* TOTAL PORTFOLIO  */}
                                        {/* {accountSummaryData?.map((item, index) => {
                                            var bookValueTotalTextColor = '';
                                            if (item.bookvalue_total < item.totalprofit_total) {
                                                bookValueTotalTextColor = 'green-text';
                                            } else if (item.bookvalue_total > item.totalprofit_total) {
                                                bookValueTotalTextColor = 'red-text';
                                            }
                                            return (
                                                <>
                                                    {index == accountSummaryData.length - 1 ? (
                                                        <tr>
                                                            <td style={{ fontWeight: 'bold' }}>Total Portfolio Value</td>
                                                            <td></td>
                                                            <td>{numberFormat(item.bookvalue_total)}</td>
                                                            <td className={bookValueTotalTextColor.toString()}>
                                                                {numberFormat(item.totalchange)}
                                                            </td>
                                                            <td className="text-center">{numberFormat(item.totalprofit_total)}</td>
                                                            <td></td>
                                                        </tr>
                                                    ) : (
                                                        ''
                                                    )}
                                                </>
                                            );
                                        })} */}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!================================== TABLE END =======================================> */}
                <div className="mt-3">
                    <AssetAllocation size="full" />
                </div>

                {/*======================================SPLINE CHART========================================*/}
                <div className="col-lg-12 col-md-12 mt-4">
                    <div className="card">
                        <div className="card-header d-xxl-flex justify-content-between">
                            <h6 className="card-title">Account Value Over Time</h6>
                            <div className="account-tab">
                                <nav>
                                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                        <button
                                            onClick={() => handelClickGraphType(1)}
                                            className="nav-link active"
                                            type="button"
                                            id="all_available_tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#all_available"
                                            role="tab"
                                            aria-controls="all_available"
                                            aria-selected="true"
                                        >
                                            All Available
                                        </button>
                                        <button
                                            onClick={() => handelClickGraphType(2)}
                                            className="nav-link"
                                            id="last_12_months_tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#last_12_months"
                                            type="button"
                                            role="tab"
                                            aria-controls="last_12_months"
                                            aria-selected="false"
                                        >
                                            Last 12 Months
                                        </button>
                                        <button
                                            onClick={() => handelClickGraphType(3)}
                                            className="nav-link"
                                            id="prev_year_tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#prev_year"
                                            type="button"
                                            role="tab"
                                            aria-controls="prev_year"
                                            aria-selected="false"
                                        >
                                            Previous Year
                                        </button>
                                        <button
                                            onClick={handleOpenDatePicker}
                                            className="nav-link"
                                            id="custom_date_tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#custom_date"
                                            type="button"
                                            role="tab"
                                            aria-controls="custom_date"
                                            aria-selected="false"
                                        >
                                            Custom Date
                                        </button>
                                    </div>
                                </nav>
                            </div>
                        </div>

                        <div className="card-body">
                            <div className="tab-content" id="nav-tabContent">
                                <div
                                    className="tab-pane fade show active"
                                    id="all_available"
                                    role="tabpanel"
                                    aria-labelledby="all_available_tab"
                                >
                                    <div className="row align-item-center">
                                        <div className="col-lg-12">
                                            <ReactApexChart
                                                options={defaultGraph1.options}
                                                series={defaultGraph1.series}
                                                type="area"
                                                height={550}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade " id="last_12_months" role="tabpanel" aria-labelledby="last_12_months_tab">
                                    <div className="row align-item-center">
                                        <div className="col-lg-12">
                                            <ReactApexChart
                                                options={defaultGraph1.options}
                                                series={defaultGraph1.series}
                                                type="area"
                                                height={550}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="prev_year" role="tabpanel" aria-labelledby="prev_year_tab">
                                    <div className="row align-item-center">
                                        <div className="col-lg-12">
                                            <ReactApexChart
                                                options={defaultGraph1.options}
                                                series={defaultGraph1.series}
                                                type="area"
                                                height={550}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="custom_date" role="tabpanel" aria-labelledby="custom_date_tab">
                                    {/* 4 CUSTOM DATE OPEN */}
                                    {/* =================DATE RANGE PICKER================ */}
                                    <CustomDateComponent />
                                    <div className="row align-item-center">
                                        <div className="col-lg-12">
                                            <ReactApexChart
                                                options={defaultGraph1.options}
                                                series={defaultGraph1.series}
                                                type="area"
                                                height={550}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CustomDateComponent />
            {loader}
        </>
    );
};

export default AssetAllocationHome;
