import HeaderLayout from 'layout/HeaderLayout';
import React from 'react';

const ProfileFinalStep = () => {
    return (
        <>
            <HeaderLayout />
            <div>
                <div className="col-lg-12 col-md-12 col-sm-12 center-block text-center" style={{ padding: '20px', marginTop: '100px' }}>
                    <div className="asset_gray_container" style={{ padding: '15px 15px', marginBottom: '20px' }}>
                        <h1 className="asset_head_text">Hello, First Name.</h1>
                        <div>
                            <div>
                                <label className="green-text">
                                    Your application is being processed. You will be contacted by our representative.
                                </label>
                            </div>
                        </div>
                        <div className="asset_head_text" style={{ marginTop: '20px', marginBottom: '10px' }}>
                            <div className="center-block text-center">
                                <div className="center-block text-center">
                                    <h3>
                                        <b>NAUVZER BABUL</b>
                                    </h3>
                                    <div className="asset_head_text" style={{ color: '#41a3b0' }}>
                                        Founder and chief executive officer
                                    </div>

                                    <div className="assetsbox_text" style={{ textTransform: 'none' }}>
                                        Nauvzer brings a wealth of experience to helping investors manage their money.
                                    </div>
                                    <div className="assetsbox_text" style={{ textTransform: 'none' }}>
                                        nbabul@smartmoneyinvest.ca
                                    </div>
                                    {/*<div class="assetsbox_text">+14166032666</div>*/}
                                    <div className="col-lg-7" style={{ marginTop: '20px', marginLeft: '24%' }}>
                                        <div className="text-left">
                                            <div className="assetsbox_text text-left" style={{ color: '#41a3b0 !important' }}>
                                                Next Steps:
                                            </div>
                                            <div className="assetsbox_text text-left">
                                                - Within 48 hours, you will be contacted by a representative. This is to ensure we meet our
                                                regulatory requirements.
                                                <br />
                                                <br />
                                                - After your call, your account will be opened and you will receive instructions on how to
                                                fund your account.
                                                <br />
                                                <br />- Your agreements will be waiting for you in your dashboard.
                                            </div>
                                            {/* <div className="clearfix" /> */}
                                        </div>
                                    </div>
                                    {/* <div className="clearfix" /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProfileFinalStep;
