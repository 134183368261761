import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
// import { Test } from 'views/admin-components/pages/Test';
// import Advisors from 'views/admin-components/pages/Advisors';
// import Advisor from 'views/admin-components/pages/Advisor';
// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/admin-components/dashboard/Default/TotalIncomeDarkCard')));

const AssetCategories = Loadable(lazy(() => import('views/admin-components/pages/AssetCategories')));
const Test = Loadable(lazy(() => import('views/admin-components/pages/Test')));
const AssetCategory = Loadable(lazy(() => import('views/admin-components/pages/AssetCategory')));
const AssetClass = Loadable(lazy(() => import('views/admin-components/pages/AssetClass')));
const AssetClasses = Loadable(lazy(() => import('views/admin-components/pages/AssetClasses')));
const Securities = Loadable(lazy(() => import('views/admin-components/pages/Securities')));
const Security = Loadable(lazy(() => import('views/admin-components/pages/Security')));
const AccountType = Loadable(lazy(() => import('views/admin-components/pages/AccountType')));
const AccountTypes = Loadable(lazy(() => import('views/admin-components/pages/AccountTypes')));
const Partners = Loadable(lazy(() => import('views/admin-components/pages/Partners')));
const Partner = Loadable(lazy(() => import('views/admin-components/pages/Partner')));
const Fee = Loadable(lazy(() => import('views/admin-components/pages/Fee')));
const Fees = Loadable(lazy(() => import('views/admin-components/pages/Fees')));
const FeesType = Loadable(lazy(() => import('views/admin-components/pages/FeesType')));
const FeesTypes = Loadable(lazy(() => import('views/admin-components/pages/FeesTypes')));
const FeesRate = Loadable(lazy(() => import('views/admin-components/pages/FeesRate')));
const FeesRates = Loadable(lazy(() => import('views/admin-components/pages/FeesRates')));
const Faqs = Loadable(lazy(() => import('views/admin-components/pages/Faqs')));
const Faq = Loadable(lazy(() => import('views/admin-components/pages/Faq')));
const Questionnaire = Loadable(lazy(() => import('views/admin-components/pages/Questionnaire')));
const Questionnaires = Loadable(lazy(() => import('views/admin-components/pages/Questionnaires')));
const Referrals = Loadable(lazy(() => import('views/admin-components/pages/Referrals')));
const Newsletter = Loadable(lazy(() => import('views/admin-components/pages/Newsletters')));
const Portfolios = Loadable(lazy(() => import('views/admin-components/pages/Portfolios')));
const AddPortfolioSecurity = Loadable(lazy(() => import('views/admin-components/pages/Portfolios/AddPortfolioSecurity')));
const AddPortfolio = Loadable(lazy(() => import('views/admin-components/pages/Portfolios/AddPortfolio')));
const Advisor = Loadable(lazy(() => import('views/admin-components/pages/Advisor')));
const Advisors = Loadable(lazy(() => import('views/admin-components/pages/Advisors')));
const RegisterUsers = Loadable(lazy(() => import('views/admin-components/pages/registeruser/RegisterdUsers')));
const RegisterUser = Loadable(lazy(() => import('views/admin-components/pages/registeruser/RegisteredUser')));
const FundTranfser = Loadable(lazy(() => import('views/admin-components/pages/FundTranfser')));
const SmarterGroupCompany = Loadable(lazy(() => import('views/admin-components/pages/groupcompany/SmarterGroupCompany')));
const CompanyInformation = Loadable(lazy(() => import('views/admin-components/pages/groupcompany/CompanyInformation')));
const CompanyNotes = Loadable(lazy(() => import('views/admin-components/pages/groupcompany/CompanyNotes')));
const RemittanceDetails = Loadable(lazy(() => import('views/admin-components/pages/groupcompany/RemittanceDetails')));
const CompanyNoteModal = Loadable(lazy(() => import('views/admin-components/pages/groupcompany/CompanyNoteModal')));
const Rebalancer = Loadable(lazy(() => import('views/admin-components/pages/rebalancer/Rebalancer')));
const ReblancerAccountView = Loadable(lazy(() => import('views/admin-components/pages/rebalancer/ReblancerAccountView')));
const Withdrawal = Loadable(lazy(() => import('views/admin-components/pages/rebalancer/Withdrawal')));
const DeployCash = Loadable(lazy(() => import('views/admin-components/pages/rebalancer/DeployCash')));
const PendingTransaction = Loadable(lazy(() => import('views/admin-components/pages/rebalancer/PendingTransaction')));
const PendingWithdrawal = Loadable(lazy(() => import('views/admin-components/pages/rebalancer/PendingWithdrawal')));
const RebalancingRules = Loadable(lazy(() => import('views/admin-components/pages/rebalancer/RebalancingRules')));
const PendingTransactionNotes = Loadable(lazy(() => import('views/admin-components/pages/rebalancer/PendingTransactionNotes')));
const PendingWithdrawalNotes = Loadable(lazy(() => import('views/admin-components/pages/rebalancer/PendingWithdrawalNotes')));
const TradingQueue = Loadable(lazy(() => import('views/admin-components/pages/rebalancer/TradingQueue')));
const UserAccount = Loadable(lazy(() => import('views/admin-components/pages/useraccount/UserAccount')));
const UserAccountNote = Loadable(lazy(() => import('views/admin-components/pages/useraccount/UserAccountNote')));
const CiEfts = Loadable(lazy(() => import('views/admin-components/pages/CiEfts')));
const CiEft = Loadable(lazy(() => import('views/admin-components/pages/CiEft')));

const isAdminLogin = localStorage.getItem('isAdminLogin');

const PrivateRoute = {
    path: '/',
    element: isAdminLogin ? <MainLayout /> : <Navigate to={'/admin'} />,
    children: [
        // -------------ASSET CATEGORY---------------//
        { path: '/admin/assetcategories', element: isAdminLogin ? <AssetCategories /> : <Navigate to={'/admin'} /> },
        { path: '/admin/assetcategory/:id', element: isAdminLogin ? <AssetCategory /> : <Navigate to={'/admin'} /> },
        { path: '/admin/assetcategory', element: isAdminLogin ? <AssetCategory /> : <Navigate to={'/admin'} /> },

        // -------------ASSET CLASS---------------//
        { path: '/admin/assetclasses', element: isAdminLogin ? <AssetClasses /> : <Navigate to={'/admin'} /> },
        { path: '/admin/assetclass', element: isAdminLogin ? <AssetClass /> : <Navigate to={'/admin'} /> },
        { path: '/admin/assetclass/:id', element: isAdminLogin ? <AssetClass /> : <Navigate to={'/admin'} /> },

        // --------------SECUROITY---------------//
        { path: '/admin/securities', element: isAdminLogin ? <Securities /> : <Navigate to={'/admin'} /> },
        { path: '/admin/security', element: isAdminLogin ? <Security /> : <Navigate to={'/admin'} /> },
        { path: '/admin/security/:id', element: isAdminLogin ? <Security /> : <Navigate to={'/admin'} /> },

        // --------------ACCOUNT TYPES---------------//
        { path: '/admin/accounttypes', element: isAdminLogin ? <AccountTypes /> : <Navigate to={'/admin'} /> },
        { path: '/admin/accounttype', element: isAdminLogin ? <AccountType /> : <Navigate to={'/admin'} /> },
        { path: '/admin/accounttype/:id', element: isAdminLogin ? <AccountType /> : <Navigate to={'/admin'} /> },

        // --------------PARTNERS---------------//
        { path: '/admin/partners', element: isAdminLogin ? <Partners /> : <Navigate to={'/admin'} /> },
        { path: '/admin/partner', element: isAdminLogin ? <Partner /> : <Navigate to={'/admin'} /> },
        { path: '/admin/partner/:id', element: isAdminLogin ? <Partner /> : <Navigate to={'/admin'} /> },

        // --------------ADVISOR---------------//
        { path: '/admin/advisors', element: isAdminLogin ? <Advisors /> : <Navigate to={'/admin'} /> },
        { path: '/admin/advisor', element: isAdminLogin ? <Advisor /> : <Navigate to={'/admin'} /> },
        { path: '/admin/advisor/:id', element: isAdminLogin ? <Advisor /> : <Navigate to={'/admin'} /> },

        // --------------FEES---------------//
        { path: '/admin/fees', element: isAdminLogin ? <Fees /> : <Navigate to={'/admin'} /> },
        { path: '/admin/fee', element: isAdminLogin ? <Fee /> : <Navigate to={'/admin'} /> },
        { path: '/admin/fee/:id', element: isAdminLogin ? <Fee /> : <Navigate to={'/admin'} /> },

        // --------------FEES TYPES---------------//
        { path: '/admin/feestypes', element: isAdminLogin ? <FeesTypes /> : <Navigate to={'/admin'} /> },
        { path: '/admin/feestype', element: isAdminLogin ? <FeesType /> : <Navigate to={'/admin'} /> },
        { path: '/admin/feestype/:id', element: isAdminLogin ? <FeesType /> : <Navigate to={'/admin'} /> },

        // --------------FEES RATES---------------//
        { path: '/admin/feesrates', element: isAdminLogin ? <FeesRates /> : <Navigate to={'/admin'} /> },
        { path: '/admin/feesrate', element: isAdminLogin ? <FeesRate /> : <Navigate to={'/admin'} /> },
        { path: '/admin/feesrate/:id', element: isAdminLogin ? <FeesRate /> : <Navigate to={'/admin'} /> },

        // --------------FEES RATES---------------//
        { path: '/admin/faqs', element: isAdminLogin ? <Faqs /> : <Navigate to={'/admin'} /> },
        { path: '/admin/faq', element: isAdminLogin ? <Faq /> : <Navigate to={'/admin'} /> },
        { path: '/admin/faq/:id', element: isAdminLogin ? <Faq /> : <Navigate to={'/admin'} /> },

        // --------------QUESTIONNAIRES---------------//
        { path: '/admin/questionnaires', element: isAdminLogin ? <Questionnaires /> : <Navigate to={'/admin'} /> },
        { path: '/admin/questionnaire/:partnerid', element: isAdminLogin ? <Questionnaire /> : <Navigate to={'/admin'} /> },
        { path: '/admin/questionnaire/:partnerid/:questionid', element: isAdminLogin ? <Questionnaire /> : <Navigate to={'/admin'} /> },

        // --------------REFERRALS---------------//
        { path: '/admin/referrals', element: isAdminLogin ? <Referrals /> : <Navigate to={'/admin'} /> },

        // --------------NEWSLETTER---------------//
        { path: '/admin/newsletters', element: isAdminLogin ? <Newsletter /> : <Navigate to={'/admin'} /> },

        // --------------PORTFOLIO---------------//
        { path: '/admin/portfolios', element: isAdminLogin ? <Portfolios /> : <Navigate to={'/admin'} /> },
        { path: '/admin/addportfoliosecurity/:partnerid', element: isAdminLogin ? <AddPortfolioSecurity /> : <Navigate to={'/admin'} /> },
        { path: '/admin/addportfolio/:partnerId/:id/:addEditId', element: isAdminLogin ? <AddPortfolio /> : <Navigate to={'/admin'} /> },
        // { path: '/admin/addportfoliosecurity/:parentId', element: isAdminLogin ? <AddPortfolioSecurity/> : <Navigate to={'/admin'} /> }

        // --------------REGISTER USER---------------//
        { path: '/admin/register_users', element: isAdminLogin ? <RegisterUsers /> : <Navigate to={'/admin'} /> },
        { path: '/admin/register_user/:userid', element: isAdminLogin ? <RegisterUser /> : <Navigate to={'/admin'} /> },

        // --------------FUND TRANSFER---------------//
        { path: '/admin/fund_transfer', element: isAdminLogin ? <FundTranfser /> : <Navigate to={'/admin'} /> },

        // --------------SMARTER GROUP COMPANY---------------//
        { path: '/admin/smarter_group_company', element: isAdminLogin ? <SmarterGroupCompany /> : <Navigate to={'/admin'} /> },
        { path: '/admin/company_information/:companyId', element: isAdminLogin ? <CompanyInformation /> : <Navigate to={'/admin'} /> },
        //  path="/to/page/:pathParam1?/:pathParam2?" component={MyPage} />  // OPTIONAL PARAMS
        { path: '/admin/company_information/register/3', element: isAdminLogin ? <CompanyInformation /> : <Navigate to={'/admin'} /> },
        { path: '/admin/company_notes/:companyId', element: isAdminLogin ? <CompanyNotes /> : <Navigate to={'/admin'} /> },
        { path: '/admin/remittance_details/:companyId', element: isAdminLogin ? <RemittanceDetails /> : <Navigate to={'/admin'} /> },
        { path: '/admin/companynotemodal/:companyId', element: isAdminLogin ? <CompanyNoteModal /> : <Navigate to={'/admin'} /> },
        { path: '/admin/rebalancers', element: isAdminLogin ? <Rebalancer /> : <Navigate to={'/admin'} /> },
        {
            path: '/admin/rebalancers_account_view/:userId/:accountNumber',
            element: isAdminLogin ? <ReblancerAccountView /> : <Navigate to={'/admin'} />
        },
        { path: '/admin/withdrawal/:userId/:accountNumber', element: isAdminLogin ? <Withdrawal /> : <Navigate to={'/admin'} /> },
        { path: '/admin/deploy_cash/:userId/:accountNumber', element: isAdminLogin ? <DeployCash /> : <Navigate to={'/admin'} /> },
        { path: '/admin/pending_transaction', element: isAdminLogin ? <PendingTransaction /> : <Navigate to={'/admin'} /> },
        { path: '/admin/pending_withdrawal', element: isAdminLogin ? <PendingWithdrawal /> : <Navigate to={'/admin'} /> },
        { path: '/admin/rebalancing_rules', element: isAdminLogin ? <RebalancingRules /> : <Navigate to={'/admin'} /> },
        {
            path: '/admin/pending_transaction_notes/:userId',
            element: isAdminLogin ? <PendingTransactionNotes /> : <Navigate to={'/admin'} />
        },
        {
            path: '/admin/pending_withdrawal_notes/:userId',
            element: isAdminLogin ? <PendingWithdrawalNotes /> : <Navigate to={'/admin'} />
        },
        { path: '/admin/trading_queue', element: isAdminLogin ? <TradingQueue /> : <Navigate to={'/admin'} /> },
        { path: '/admin/user_account', element: isAdminLogin ? <UserAccount /> : <Navigate to={'/admin'} /> },
        { path: '/admin/user_account_notes/:userId', element: isAdminLogin ? <UserAccountNote /> : <Navigate to={'/admin'} /> },
        { path: '/admin/ci-efts', element: isAdminLogin ? <CiEfts /> : <Navigate to={'/admin'} /> },
        { path: '/admin/ci-eft', element: isAdminLogin ? <CiEft /> : <Navigate to={'/admin'} /> },
        { path: '/admin/ci-eft/:id', element: isAdminLogin ? <CiEft /> : <Navigate to={'/admin'} /> },
        { path: '/admin/test', element: isAdminLogin ? <Test /> : <Navigate to={'/admin'} /> }

        // --------------------TEST-----------------------//
        // { path: '/smartmoney/test', element: isAdminLogin ? <Test /> : <Navigate to={'/admin'} /> },
    ]
};
export default PrivateRoute;
