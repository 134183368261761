
import MinimalLayout from 'layout/MinimalLayout';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from 'ui-component/Loadable';
import React from 'react'

const SmartGroupRegister = Loadable(lazy(() => import('views/user-components/pages/company-register/SmartGroupRegister')));
const SmartGroupActivation = Loadable(lazy(() => import('views/user-components/pages/company-register/SmartGroupActivation')));
const AdvisorAllPersonal = Loadable(lazy(() => import('views/user-components/pages/company-register/AdvisorAllPersonal')));

const isEmployerLogin = localStorage.getItem('isEmployerLogin')
const isAdvisorLogin = localStorage.getItem('isAdvisorLogin')
const OtherLoginRoutes = {
    path: '/',
    // element: isUserLogin ? <MinimalLayout /> : <Navigate to={`${partnerName}/signin`} />,
    element: (isEmployerLogin || isAdvisorLogin) ? <MinimalLayout /> : <Navigate to={`/smartmoney/signin`} />,
    children: [
        { path: '/smarter_group_register', element: isEmployerLogin ? <SmartGroupRegister /> : <Navigate to={'/smartmoney/signin'} /> },
        { path: '/advisor_all_personal', element: isAdvisorLogin ? <AdvisorAllPersonal /> : <Navigate to={'/smartmoney/signin'} /> },
    ]
}

export default OtherLoginRoutes
