// assets
import { IconLetterN } from '@tabler/icons';
// constant
const icons = {
    IconLetterN
}

const NewsletterMenuItem = {
    id: 'newsletter',
    type: 'group',
    children: [
        {
            id: 'newsletter',
            title: 'Newsletter',
            type: 'collapse',
            icon: icons.IconLetterN,
            url: '/admin/newsletters',
            breadcrumbs: false,

            children: [
                {
                    id: 'newsletters',
                    title: 'Newsletter',
                    type: 'item',
                    url: '/newsletters',
                    target: true
                }
            ]
        }
    ]
}

export default NewsletterMenuItem
