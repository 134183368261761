import DashboardHeader from 'layout/DashboardLayout/Header';
import Footer from 'layout/DashboardLayout/Footer';
import NavBar from 'layout/DashboardLayout/NavBar';
import SideBar from 'layout/DashboardLayout/SideBar';
import React, { useEffect, useState } from 'react';
import { Link, NavLink, Outlet, useLocation } from 'react-router-dom';

const DashBoardLayout = () => {
    // const [sideMenuId, setSideMenuId] = useState(1); //load first time layout
    var sideMenuId = 1;
    var profileMenuActive = false;
    var reportMenuActive = false;
    var financialPlanMenuActive = false;

    //assigning location variable
    const location = useLocation();

    //destructuring pathname from location
    const { pathname } = location;

    //Javascript split method to get the name of the path in array
    const routeFileName = pathname.split('/');
    var routeName = routeFileName[1];
    // console.log(routeName);
    // ACTIVE MAIN NAV BAR

    if (
        routeName == 'main' ||
        routeName == 'asset_allocation_accounts' ||
        routeName == 'message_center' ||
        routeName == 'fund_transfer' ||
        routeName == 'fees' ||
        routeName == 'asset_drift'
    ) {
        sideMenuId = 2;
    } else if (routeName == 'data_vault') {
        sideMenuId = 3;
    } else if (
        routeName == 'home' ||
        routeName == 'personal' ||
        routeName == 'goals' ||
        routeName == 'accounts' ||
        routeName == 'assets' ||
        routeName == 'income_expenses' ||
        routeName == 'estate_planning' ||
        routeName == 'account_summary' ||
        routeName == 'cashflow' ||
        routeName == 'networth'
    ) {
        sideMenuId = 1;
    }

    // SHOW HIDE NESTED LEFT MENU
    if (
        routeName == 'personal' ||
        routeName == 'goals' ||
        routeName == 'accounts' ||
        routeName == 'assets' ||
        routeName == 'income_expenses' ||
        routeName == 'estate_planning'
    ) {
        profileMenuActive = true;
    } else if (routeName == 'account_summary' || routeName == 'cashflow' || routeName == 'networth') {
        reportMenuActive = true;
    } else if (
        routeName == 'projected_cash_flow' ||
        routeName == 'projected_net_worth' ||
        routeName == 'insurance_calculator' ||
        routeName == 'financial_goals'
    ) {
        financialPlanMenuActive = true;
    }

    const clickNavLink = (sideMenuId) => {
        switch (sideMenuId) {
            case 1:
                return <SmartPlannerComponent />;
            case 2:
                return <SmartAllocationComponent />;
            case 3:
                return <SmartDataVault />;
            default:
                return <SmartAllocationComponent />;
        }
    };

    const SmartAllocationComponent = () => {
        return (
            <>
                <div className="app-navbar">
                    <div className="sidebar-nav dashboard-side-nav">
                        <ul className="metismenu" id="sidebarNav">
                            <li>
                                <NavLink className={`${(routeName == 'main') ? 'left-menu-active' : ''}`} to={'/main'}>
                                    <img className="img-fluid" src="/images/menu-icon-03.png" alt="nav-icon" />
                                    <span className="nav-title">HOME</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className={`${(routeName == 'asset_allocation_accounts') ? 'left-menu-active' : ''}`} to={'/asset_allocation_accounts'}>
                                    <img className="img-fluid" src="/images/menu-icon-03.png" alt="nav-icon" />
                                    <span className="nav-title">ACCOUNTS</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className={`${(routeName == 'asset_drift') ? 'left-menu-active' : ''}`} to={'/asset_drift'}>
                                    <img className="img-fluid" src="/images/menu-icon-03.png" alt="nav-icon" />
                                    <span className="nav-title">ASSET DRIFT</span>
                                </NavLink>
                            </li>

                            <li>
                                <NavLink className={`${(routeName == 'message_center') ? 'left-menu-active' : ''}`} to={'/message_center'}>
                                    <img className="img-fluid" src="/images/menu-icon-03.png" alt="nav-icon" />
                                    <span className="nav-title">MESSAGE CENTER</span>
                                </NavLink>
                            </li>

                            <li>
                                <NavLink className={`${(routeName == 'fund_transfer') ? 'left-menu-active' : ''}`} to={'/fund_transfer'}>
                                    <img className="img-fluid" src="/images/menu-icon-03.png" alt="nav-icon" />
                                    <span className="nav-title">FUND TRANSFER</span>
                                </NavLink>
                            </li>

                            <li>
                                <NavLink className={`${(routeName == 'fees') ? 'left-menu-active' : ''}`} to={'/fees'}>
                                    <img className="img-fluid" src="/images/menu-icon-03.png" alt="nav-icon" />
                                    <span className="nav-title">FEES</span>
                                </NavLink>
                            </li>
                        </ul>

                        {/* COMMON FOOTER */}
                        <SideBarFooter />
                    </div>
                </div>
            </>
        );
    };

    const SmartPlannerComponent = () => {
        return (
            <>
                <div className="app-navbar">
                    <div className="sidebar-nav dashboard-side-nav ">
                        <ul className="metismenu" id="sidebarNav">
                            <li>
                                <NavLink className={`${(routeName == 'home') ? 'left-menu-active' : ''}`} to={'/home'}>
                                    <img className="img-fluid" src="/images/menu-icon-06.png" alt="menu-icon" />
                                    <span className="nav-title">Overview</span>
                                </NavLink>
                            </li>

                            <li>
                                <NavLink
                                    activeClassName="active"
                                    to={'/personal'}
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseExample"
                                    aria-expanded="false"
                                    aria-controls="collapseExample"
                                    id="profileCollapsed"
                                // className='collapsed'
                                >
                                    <i className="fa-solid fa-user"></i>
                                    <span className="nav-title">Profile</span>
                                </NavLink>

                                <ul className={`sub-menu collapse ${profileMenuActive ? 'show' : ''}`} id="collapseExample">
                                    <li>
                                        <NavLink className={`${(routeName == 'personal') ? 'left-menu-active' : ''}`} to={'/personal'}>
                                            <img className="img-fluid" src={'/images/menu-icon-02.png'} alt="smart allocation personal" />
                                            Personal
                                        </NavLink>
                                    </li>
                                    <li className>
                                        <NavLink className={`${(routeName == 'goals') ? 'left-menu-active' : ''}`} to={'/goals'}>
                                            <img className="img-fluid" src={'/images/menu-icon-03.png'} alt="smart allocation personal" />
                                            Goals
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink className={`${(routeName == 'accounts') ? 'left-menu-active' : ''}`} to={'/accounts'}>
                                            <img className="img-fluid" src={'/images/menu-icon-04.png'} alt="smart allocation personal" />
                                            Accounts
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink className={`${(routeName == 'assets') ? 'left-menu-active' : ''}`} to={'/assets'}>
                                            <img className="img-fluid" src={'/images/menu-icon-05.png'} alt="smart allocation personal" />
                                            Assets
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink className={`${(routeName == 'income_expenses') ? 'left-menu-active' : ''}`} to={'/income_expenses'}>
                                            <img className="img-fluid" src={'/images/menu-icon-07.png'} alt="smart allocation personal" />
                                            Income/Expenses
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink className={`${(routeName == 'estate_planning') ? 'left-menu-active' : ''}`} to={'/estate_planning'}>
                                            <img className="img-fluid" src={'/images/menu-icon-01.png'} alt="smart allocation personal" />
                                            Estate Planning
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>

                            <li>
                                <NavLink
                                    activeClassName="active"
                                    to={'/account_summary'}
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseExampleReport"
                                    aria-expanded="false"
                                    aria-controls="collapseExampleReport"
                                >
                                    <i className="fa-solid fa-user"></i>
                                    <span className="nav-title">Report</span>
                                </NavLink>
                                <ul className={`sub-menu collapse ${reportMenuActive ? 'show' : ''}`} id="collapseExampleReport">
                                    <li>
                                        <NavLink activeClassName="active" to={'/account_summary'}>
                                            <img className="img-fluid" src={'/images/menu-icon-02.png'} alt="account_summary" />
                                            Account Summary
                                        </NavLink>
                                    </li>
                                    <li className>
                                        <NavLink activeClassName="active" to={'/cashflow'}>
                                            <img className="img-fluid" src={'/images/menu-icon-03.png'} alt="cash_flow" />
                                            Cash Flow
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeClassName="active" to={'/networth'}>
                                            <img className="img-fluid" src={'/images/menu-icon-04.png'} alt="net_flow" />
                                            Net Flow
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>

                            <li>
                                <NavLink activeClassName="active" to={'/projected_cash_flow'}>
                                    <img className="img-fluid" src="/images/menu-icon-03.png" alt="financial-icon" />
                                    <span className="nav-title">Financial Plan</span>
                                </NavLink>
                                <ul className={`sub-menu collapse ${financialPlanMenuActive ? 'show' : ''}`} id="collapseExampleReport">
                                    <li>
                                        <NavLink className={`${(routeName == 'projected_cash_flow') ? 'left-menu-active' : ''}`} to={'/projected_cash_flow'}>
                                            <img className="img-fluid" src={'/images/menu-icon-02.png'} alt="account_summary" />
                                            Projected Cash Flow
                                        </NavLink>
                                    </li>
                                    <li className>
                                        <NavLink className={`${(routeName == 'projected_net_worth') ? 'left-menu-active' : ''}`} to={'/projected_net_worth'}>
                                            <img className="img-fluid" src={'/images/menu-icon-03.png'} alt="cash_flow" />
                                            Projected Net Worth
                                        </NavLink>
                                    </li>
                                    <li className>
                                        <NavLink className={`${(routeName == 'financial_goals') ? 'left-menu-active' : ''}`} to={'/financial_goals'}>
                                            <img className="img-fluid" src={'/images/menu-icon-03.png'} alt="cash_flow" />
                                            Financial Goal
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink className={`${(routeName == 'insurance_calculator') ? 'left-menu-active' : ''}`} to={'/insurance_calculator'}>
                                            <img className="img-fluid" src={'/images/menu-icon-04.png'} alt="net_flow" />
                                            Insurance Calculator
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <SideBarFooter />
                    </div>
                </div>
            </>
        );
    };

    const SmartDataVault = () => {
        return (
            <>
                <div className="app-navbar">
                    <div className="sidebar-nav dashboard-side-nav">
                        <ul className="metismenu" id="sidebarNav">
                            <li>
                                <NavLink className={`${(routeName == 'data_vault') ? 'left-menu-active' : ''}`} to={'/data_vault'}>
                                    <img className="img-fluid" src="/images/menu-icon-06.png" alt="menu-icon" />
                                    <span className="nav-title">SUMMARY</span>
                                </NavLink>
                            </li>
                        </ul>
                        <SideBarFooter />
                    </div>
                </div>
            </>
        );
    };

    const SideBarFooter = () => {
        return (
            <>
                <ul className="sidebar-footer-menu">
                    <li>
                        <NavLink to={'#'}>
                            <i className="fa-solid fa-gear" />
                            Setting
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={'#'} className="text-red" alt="logout-icon">
                            <i className="fa-solid fa-arrow-right-from-bracket" />
                            Log Out
                        </NavLink>
                    </li>
                </ul>
            </>
        );
    };

    const clickNavMenu = (id) => {
        sideMenuId = id;
    };


    // useEffect(() => {
    //     sideMenuId = 1
    // }, [sideMenuId]);

    return (
        <>
            <DashboardHeader />
            <div className="app-container">
                {/* SIDEBAR MENU  */}
                {clickNavLink(sideMenuId)}
                {/* EO SIDEBAR MENU 


                {/* BEGIN APP-MAIN  */}
                <div className="app-main" id="main">
                    <div className="container-fluid">
                        {/* NAVBAR  */}
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="main-menu">
                                    <ul>
                                        <li>
                                            <div onClick={() => clickNavMenu(2)}>
                                                <NavLink activeClassName="active" to={'/main'} className={sideMenuId == 2 ? 'active' : ''}>
                                                    <img className="img-fluid" src="/images/icon-01.png" alt="smart allocation" />
                                                    Smart Allocation
                                                </NavLink>
                                            </div>
                                        </li>
                                        <li>
                                            <div onClick={() => clickNavMenu(1)}>
                                                <NavLink activeClassName="active" to={'/home'} className={sideMenuId == 1 ? 'active' : ''}>
                                                    <img className="img-fluid" src="/images/icon-02.png" alt="smart planner" />
                                                    Smart Planner
                                                </NavLink>
                                            </div>
                                        </li>
                                        <li>
                                            <div onClick={() => clickNavMenu(3)}>
                                                <NavLink
                                                    activeClassName="active"
                                                    to={'/data_vault'}
                                                    className={sideMenuId == 3 ? 'active' : ''}
                                                >
                                                    <img className="img-fluid" src="/images/icon-03.png" alt="smart vault" />
                                                    Smart Vault
                                                </NavLink>
                                            </div>
                                        </li>
                                        {/* <li>
                                <NavLink to={'/test'}>
                                    <img className="img-fluid" src="images/icon-04.png" alt='fees' />
                                    Fees
                                </NavLink>
                            </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* EO NAVBAR  */}

                        {/* MAIN COMPONENT  */}
                        <Outlet />
                        {/* EO MAIN COMPONENT  */}
                        {/* <AssetAllocation /> */}
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
};

export default DashBoardLayout;
