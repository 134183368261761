// assets
import { IconLetterP } from '@tabler/icons';

// constant
const icons = {
    IconLetterP
};

const PortfolioMenuItem = {
    id: 'portfolio',
    // title: 'Pages',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'portfolios',
            title: 'Portfolios',
            type: 'collapse',
            icon: icons.IconLetterP,
            url: '/admin/assetcategories',
            breadcrumbs: false,
            children: [
                {
                    id: 'assetcategory',
                    title: 'Asset Category',
                    type: 'item',
                    url: '/assetcategories',
                    target: true
                },
                {
                    id: 'assetclass',
                    title: 'Asset Class',
                    type: 'item',
                    url: '/assetclasses',
                    target: true
                },
                {
                    id: 'securities',
                    title: 'Securities',
                    type: 'item',
                    url: '/securities',
                    target: true
                },
                {
                    id: 'accounttype',
                    title: 'Account Type',
                    type: 'item',
                    url: '/accounttypes',
                    target: true
                },
                {
                    id: 'questionnaire',
                    title: 'Questionnaire',
                    type: 'item',
                    url: '/questionnaires',
                    target: true
                },
                {
                    id: 'portfolio',
                    title: 'Portfolio',
                    type: 'item',
                    url: '/portfolios',
                    target: true
                }
            ]
        }
    ]
};

export default PortfolioMenuItem;
