// import * as React from 'react';
import { useState, useEffect } from 'react';
// import AppBar from '@mui/material/AppBar';
// import Box from '@mui/material/Box';
// import Toolbar from '@mui/material/Toolbar';
// import IconButton from '@mui/material/IconButton';
// import Typography from '@mui/material/Typography';
// import Menu from '@mui/material/Menu';
// import Container from '@mui/material/Container';
// import Avatar from '@mui/material/Avatar';
// import MenuItem from '@mui/material/MenuItem';
import SmiLogo from 'assets/images/logo.png';
import avatarLogo from 'assets/images/user-avatar.jpg';
import { useNavigate } from 'react-router';

import '../views/user-components/pages/signup-profile/profile.css';
import { Link } from '@mui/material';

// const pages = ['Products', 'Pricing', 'Blog'];
const settings = ['Logout']; // add multiple li item in after login in to dashboard.

const HeaderLayout = ({ setIsComponentLoading }) => {
    // console.log(setIsComponentLoading);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const navigate = useNavigate();
    const partnerUrl = localStorage.getItem('partner_url');
    const [anchorElNav, setAnchorElNav] = useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    // TODO:: AFTER GOING TO DASHBOARD RETURN TO PERSON WHILE YOU CLICKED ON CLIENTS.
    const onClickComponent = (id) => {
        if (setIsComponentLoading) {
            setIsComponentLoading(id);
        } else {
            navigate('/admin_dashboard');
        }
    };

    const logout = () => {
        localStorage.clear();
        navigate('/');
    };

    useEffect(() => {}, []);

    return (
        <>
            <header className="app-header top-bar-01">
                <nav className="navbar navbar-expand-md">
                    <div className="container-fluid">
                        <a href="/" className="nav-link sidebar-toggle">
                            <span className="navbar-toggler-icon" />
                        </a>
                        <div className="navbar-header d-flex align-items-center justify-content-around">
                            <a className="navbar-brand" href="/">
                                <img src={SmiLogo} className="img-fluid logo" alt="logo" />
                            </a>
                        </div>
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon" />
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <div className="navigation d-flex">
                                <ul className="navbar-nav nav-left">
                                    <li className="nav-item">
                                        <a href="/" className="nav-link sidebar-toggle">
                                            <i className="fa-solid fa-sliders" />
                                        </a>
                                    </li>
                                </ul>
                                <ul className="navbar-nav nav-right">
                                    <li className="nav-item">
                                        <a className="nav-link" href="/">
                                            <i className="fa-solid fa-envelope" />
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link search" href="/">
                                            <i className="fa-solid fa-magnifying-glass" />
                                        </a>
                                    </li>
                                    <li className="nav-item user-profile">
                                        <a href="/" className="nav-link">
                                            {/* <img src={avatarLogo} alt="avtar-img" /> */}
                                            <span className="bg-success user-status" />
                                        </a>
                                    </li>

                                    <li className="nav-item dropdown user-profile" style={{ marginRight: '20px' }}>
                                        <Link
                                            className="nav-link dropdown-toggle"
                                            href="#"
                                            id="navbarDropdown"
                                            role="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <img src={avatarLogo} alt="avtar-img" />
                                        </Link>
                                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown" style={{ minWidth: '1rem' }}>
                                            {/* TODO:: SHOW ONLY AFTER COMPANY LOGIN  */}
                                            {/* <li>
                                                <Link className="dropdown-item" onClick={() => onClickComponent(1)}>
                                                    Home
                                                </Link>
                                            </li>

                                            <li>
                                                <Link className="dropdown-item" onClick={() => onClickComponent(2)}>
                                                    Client
                                                </Link>
                                            </li> */}

                                            <li>
                                                <Link className="dropdown-item" onClick={logout}>
                                                    Logout
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>

            {/* <AppBar position="static" style={{ backgroundColor: '#525252' }}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, mr: 2, display: { xs: 'flex', md: 'flex' } }}>
                            <img src={SmiLogo} alt="smi_logo" />
                        </Typography>

                        <Box sx={{ flexGrow: 0 }}>
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                            </IconButton>
                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                {settings.map((setting) => (
                                    <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                        <Typography textAlign="center" onClick={logOut}>
                                            {setting}
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar> */}
        </>
    );
};
export default HeaderLayout;
