// theme constant
export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;
export const API_BASE_URL = "https://smartmoneyinc.ca/backend/";

//export const API_BASE_URL = 'http://localhost:8000/';
export const GoogleReCaptchKEY = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';

export const colorArray = [
    '#3CA4B1',
    '#54C4C6',
    '#9DFFFC',
    '#535353',
    '#818382',
    '#AAAAAA',
    '#C0C0C0',
    '#3CA4B1',
    '#54C4C6',
    '#73C9C6',
    '#535353',
    '#818382',
    '#AAAAAA',
    '#C0C0C0'
];

export const tradingQueueJson = [
    {
        portfolio: 'Smart Money',
        securities: [
            {
                type: 'U.S. High Yield Bonds',
                name: 'iShares U.S. High Yield Bond Index',
                symbol: 'XHY',
                quantity: 1
            },
            {
                type: 'Canadian Investment Grade Bonds',
                name: 'Vanguard CAD Aggregate Bond Index',
                symbol: 'VAB',
                quantity: 1
            },
            {
                type: 'Canadian Short-Term Investment Grade Bonds',
                name: 'iShares S&P/TSX Capped Consumer Staples Index ETF',
                symbol: 'VSB',
                quantity: 1
            },
            {
                type: 'Canadian Floating Rate Bonds',
                name: 'iShares Floating Rate Index ETF',
                symbol: 'XFR',
                quantity: 1
            }
        ],
        accounts: [
            {
                name: 'ALADIN MAWANI',
                type: 'Deploy Cash',
                id: 1,
                account_no: '1000533826',
                shares: [
                    {
                        type: 'BUY',
                        quantity: 5,
                        is_modified: false
                    },
                    {
                        type: 'BUY',
                        quantity: 5,
                        is_modified: false
                    },
                    {
                        type: 'BUY',
                        quantity: 5,
                        is_modified: false
                    },
                    {
                        type: 'SELL',
                        quantity: 0,
                        is_modified: true
                    }
                ]
            },
            {
                name: 'TEST',
                type: 'rebalancer',
                id: 1,
                account_no: '1000533813',
                shares: [
                    {
                        type: 'BUY',
                        quantity: 100,
                        is_modified: false
                    },
                    {
                        type: 'BUY',
                        quantity: 10,
                        is_modified: true
                    },
                    {
                        type: 'BUY',
                        quantity: 5,
                        is_modified: false
                    },
                    {
                        type: 'SELL',
                        quantity: 3,
                        is_modified: false
                    }
                ]
            }
        ]
    },

    {
        portfolio: 'Wealth Port',
        securities: [
            {
                type: 'U.S. High Yield Bonds',
                name: 'iShares U.S. High Yield Bond Index',
                symbol: 'XHY',
                quantity: 1
            },
            {
                type: 'Canadian Investment Grade Bonds',
                name: 'Vanguard CAD Aggregate Bond Index',
                symbol: 'VAB',
                quantity: 1
            },
            {
                type: 'Canadian Short-Term Investment Grade Bonds',
                name: 'Vanguard CAD Short-Term Bond Index',
                symbol: 'VSB',
                quantity: 1
            },
            {
                type: 'Canadian Floating Rate Bonds',
                name: 'iShares Floating Rate Index ETF',
                symbol: 'XFR',
                quantity: 1
            }
        ],
        accounts: [
            {
                name: 'ALADIN MAWANI',
                type: 'Deploy Cash',
                id: 1,
                account_no: '1000533826',
                shares: [
                    {
                        type: 'BUY',
                        quantity: 5,
                        is_modified: false
                    },
                    {
                        type: 'BUY',
                        quantity: 5,
                        is_modified: true
                    },
                    {
                        type: 'BUY',
                        quantity: 5,
                        is_modified: false
                    },
                    {
                        type: 'SELL',
                        quantity: 8,
                        is_modified: false
                    }
                ]
            },
            {
                name: 'TEST',
                type: 'Rebalancer',
                id: 1,
                account_no: '1000533813',
                shares: [
                    {
                        type: 'BUY',
                        quantity: 5,
                        is_modified: false
                    },
                    {
                        type: 'BUY',
                        quantity: 5,
                        is_modified: false
                    },
                    {
                        type: 'BUY',
                        quantity: 5,
                        is_modified: false
                    },
                    {
                        type: 'SELL',
                        quantity: 500,
                        is_modified: true
                    }
                ]
            },
            {
                name: 'ALADIN MAWANI',
                type: 'Deploy Cash',
                id: 1,
                account_no: '1000533826',
                shares: [
                    {
                        type: 'BUY',
                        quantity: 5,
                        is_modified: false
                    },
                    {
                        type: 'BUY',
                        quantity: 5,
                        is_modified: true
                    },
                    {
                        type: 'BUY',
                        quantity: 5,
                        is_modified: false
                    },
                    {
                        type: 'SELL',
                        quantity: 8,
                        is_modified: false
                    }
                ]
            },
            {
                name: 'TEST',
                type: 'Rebalancer',
                id: 1,
                account_no: '1000533813',
                shares: [
                    {
                        type: 'BUY',
                        quantity: 5,
                        is_modified: false
                    },
                    {
                        type: 'BUY',
                        quantity: 5,
                        is_modified: false
                    },
                    {
                        type: 'BUY',
                        quantity: 5,
                        is_modified: false
                    },
                    {
                        type: 'SELL',
                        quantity: 500,
                        is_modified: true
                    }
                ]
            }
        ]
    }
];
