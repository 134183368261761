import { numberFormat } from 'hooks/useNumberFormat';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { colorArray } from 'store/constant';

const RadialBarChart = (props) => {
    return (
        <>
            <ReactApexChart
                series={props.series}
                type="radialBar"
                height={props.height}
                options={{
                    // title: {
                    //     text: "Student PieChart"
                    // },
                    legend: {
                        show: true,
                        showForSingleSeries: false,
                        showForNullSeries: true,
                        showForZeroSeries: true,
                        position: 'bottom',
                        // horizontalAlign: 'center',
                        floating: false,
                        fontSize: '14px',
                        fontFamily: 'Helvetica, Arial',
                        fontWeight: 400,
                        formatter: undefined,
                        inverseOrder: false,
                        width: undefined,
                        height: undefined,
                        tooltipHoverFormatter: undefined,
                        customLegendItems: [],
                        offsetX: 0,
                        offsetY: 0,
                        labels: {
                            colors: undefined,
                            useSeriesColors: false
                        },

                        // LEGENDS MARGIN FROM
                        itemMargin: {
                            horizontal: 5,
                            vertical: 3
                        },
                        onItemClick: {
                            toggleDataSeries: true
                        },
                        onItemHover: {
                            highlightDataSeries: true
                        }
                    },

                    dataLabels: {
                        enabled: false
                    },
                    tooltip: {
                        y: {
                            formatter: function (value) {
                                return numberFormat(value);
                            }
                        }
                    },
                    noData: { text: 'Loading graph...' },
                    colors: colorArray,

                    plotOptions: {
                        radialBar: {
                            dataLabels: {
                                name: {
                                    fontSize: '12px'
                                },
                                value: {
                                    show: true,
                                    fontSize: '14px',
                                    fontFamily: 'Helvetica, Arial, sans-serif',
                                    fontWeight: 400,
                                    // color: "#FF0000",
                                    color: undefined,
                                    offsetY: 16,
                                    formatter: function (val) {
                                        // console.log(val)
                                        return val + '%';
                                    }
                                },
                                total: {
                                    show: true,
                                    fontSize: '14px',
                                    color: '#041f42',
                                    label: ['Current Asset Allocation'],
                                    formatter: function (w) {
                                        return numberFormat(Number(props.total));
                                    }
                                }
                            }
                        }
                    },
                    labels: props.titleName
                }}
            />
        </>
    );
};

export default RadialBarChart;
