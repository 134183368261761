import MainLayout from 'layout/MainLayout';
import MinimalLayout from 'layout/MinimalLayout';
import HeaderLayout from 'layout/HeaderLayout';
import React from 'react';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from 'ui-component/Loadable';
import AdminDashboard from 'views/admin-components/company/AdminDashboard';

const ProfileSteps = Loadable(lazy(() => import('views/user-components/pages/signup-profile/ProfileSteps')));

const isUserLogin = localStorage.getItem('isUserLogin');
const userId = localStorage.getItem('user_id');
const partnerName = localStorage.getItem('partner_url');

const ProfileRoutes = {
    path: '/',
    // element: isUserLogin ? <MinimalLayout /> : <Navigate to={`${partnerName}/signin`} />,
    element: isUserLogin ? <MinimalLayout /> : <Navigate to={`/smartmoney/signin`} />,
    children: [
        { path: '/signup/profile/:userid', element: isUserLogin ? <ProfileSteps /> : <Navigate to={`/smartmoney/signin`} /> }, //NAVIGATE({ TO, REPLACE, STATE }
        { path: '/admin_dashboard', element: isUserLogin ? <AdminDashboard /> : <Navigate to={'/company_login'} />, },

    ]
};

export default ProfileRoutes;
