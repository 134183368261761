import { useRoutes } from 'react-router-dom';

// routes
// import MainRoutes from './MainRoutes';
// import AuthenticationRoutes from './AuthenticationRoutes';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoutes';
import ClientRoutes from './ClientRoutes';
import config from 'config';
import ProfileRoutes from './ProfileRoutes';
import OtherLoginRoutes from './OtherLoginRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([PublicRoute, PrivateRoute, ProfileRoutes ,ClientRoutes, OtherLoginRoutes]);
}
