import HeaderLayout from 'layout/HeaderLayout';
import React, { useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridRow, GridToolbar } from '@mui/x-data-grid';
import {
    FormControl,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem,
    Radio,
    Select,
    TextField,
    Typography
} from '@mui/material';
import { useNavigate } from 'react-router';
import { useFullPageLoader } from 'hooks/useFullPageLoader';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axiosInstance from 'custom-axios';
import clsx from 'clsx';
import SearchIcon from '@mui/icons-material/Search';
import { numberFormat } from 'hooks/useNumberFormat';
import useDebounce from 'hooks/useDebounce';

const AdminDashboard = () => {
    const [isComponentLoading, setIsComponentLoading] = useState(1); // 1 FOR CLIENTS 2: ADVISORS
    const navigate = useNavigate();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [clientUserData, setClientUserData] = useState();
    const [advisorClientData, setAdvisorClientsData] = useState();
    const [advisorList, setAdvisorList] = useState([]);
    const [selectedAdvisor, setSelectedAdvisor] = useState();
    const [filteredAdvisorData, setFilteredAdvisorData] = useState([]);
    const [filteredClientsData, setFilteredClientsData] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [search, setSearch] = useState('');

    const debouncedSearchTerm = useDebounce(search, 500);
    // CHANGE ODD EVEN ROWS COLOR IN TABLE
    function CustomRow(props) {
        const { className, index, ...other } = props;
        return <GridRow index={index} className={clsx(className, index % 2 === 0 ? 'odd' : 'even')} {...other} />;
    }

    const fetchUserAllPersonal = async () => {
        showLoader();
        try {
            const response = await axiosInstance.get(`api/allPersonal`);
            setClientUserData(response.data);
            setFilteredClientsData(response.data);
            // if (response.success) {
            //     const isTradeComplete = response.data.filter((item) => item.is_trade_completed == 0);
            //     setPendingTransactionData(isTradeComplete);
            // }
        } catch (error) {
            toast.error(error.message);
        } finally {
            hideLoader();
        }
    };

    const fetchUserAllPersonalDetails = async () => {
        showLoader();
        try {
            const response = await axiosInstance.get(`api/allPersonalDetails/${1}`);
            setAdvisorClientsData(response.data);
            setFilteredAdvisorData(response.data);
            let arr = response.advisorData.filter((item) => item.advisor !== '');
            const output = [...new Map(arr.map((row) => [row.advisorId, row])).values()];
            setAdvisorList(output);
        } catch (error) {
            toast.error(error.message);
        } finally {
            hideLoader();
        }
    };

    const searchClients = useMemo(() => {
        if (!search) return clientUserData;

        return clientUserData.filter((item) => {
            return item.personName.toLowerCase().includes(debouncedSearchTerm.toLowerCase());
            // ||item.spousalName take 2 params as search
        });
    }, [debouncedSearchTerm, clientUserData]);

    // console.log(searchClients);5
    const handleCellClick = (param, event) => {
        event.stopPropagation();
    };

    const handleRowClick = (param, event) => {
        event.stopPropagation();
    };

    //----------------TABLE DATA------------------//
    const columns = [
        {
            field: 'notes',
            headerName: '',
            width: 80,
            renderCell: (cellValues) => {
                return <Radio size="small" onClick={() => handleClickUser(cellValues)} />;
            }
        },
        { field: 'personName', headerName: 'Person Name', width: 130 },
        { field: 'personDob', headerName: 'Person DOB', width: 170 },
        { field: 'spousalDob', headerName: 'Spouse name', width: 150 },
        { field: 'spousalName', headerName: 'Spouse DOB', width: 150 }
    ];
    //---------------- clients TABLE DATA------------------//
    const clientsColumns = [
        { field: 'client_name', headerName: 'Client', width: 130 },
        { field: 'portfolio_type', headerName: 'Company', width: 150 },
        { field: 'client_email', headerName: 'Email', width: 170 },
        { field: 'advisor_name', headerName: 'Advisor', width: 150 },
        { field: 'account_open_date', headerName: 'Account Created On', width: 150 },
        {
            field: 'total_market_value',
            headerName: 'Market Value',
            width: 150,
            renderCell: (cellValues) => {
                return numberFormat(cellValues.row.total_market_value);
            }
        },
        {
            field: 'fees_value',
            headerName: 'Total',
            width: 150,
            renderCell: (cellValues) => {
                return numberFormat(cellValues.row.fees_value);
            }
        },
        {
            field: 'tax_value',
            headerName: 'Tax',
            width: 150,
            renderCell: (cellValues) => {
                return numberFormat(cellValues.row.fees_value);
            }
        },
        {
            field: 'daily_fee',
            headerName: 'Total(Inc. Tax)',
            width: 150,
            renderCell: (cellValues) => {
                return numberFormat(cellValues.row.fees_value);
            }
        },
        {
            field: 'referral_fees_value',
            headerName: 'Total',
            width: 150,
            renderCell: (cellValues) => {
                return numberFormat(cellValues.row.fees_value);
            }
        },
        {
            field: 'referral_tax_value',
            headerName: 'Tax',
            width: 150,
            renderCell: (cellValues) => {
                return numberFormat(cellValues.row.fees_value);
            }
        },
        {
            field: 'referral_fee',
            headerName: 'Total(Inc. Tax)',
            width: 150,
            renderCell: (cellValues) => {
                return numberFormat(cellValues.row.fees_value);
            }
        }
    ];

    const handleClickUser = (data) => {
        localStorage.setItem('user_id', data.row.id);
        navigate('/home');
    };

    const handleChangeAdvisor = (e) => {
        const id = e.target.value;
        setSelectedAdvisor(id);
        if (id == 0) {
            setFilteredAdvisorData(advisorClientData);
        } else {
            let dropdownFilteredData = [];
            advisorClientData.forEach((item) => {
                if (item.advisor_id == id) {
                    dropdownFilteredData.push(item);
                }
            });

            if (dropdownFilteredData.length > 0) {
                setFilteredAdvisorData([]);
                setFilteredAdvisorData(dropdownFilteredData);
            } else {
                setFilteredAdvisorData(advisorClientData);
            }
        }
    };

    const handleSearch = (event) => {
        var lowerCase = event.target.value.toLowerCase();
        setSearchValue(lowerCase);

        if (lowerCase == 0) {
            setFilteredClientsData(clientUserData);
        } else {
            let dropdownFilteredData = [];
            clientUserData.forEach((item) => {
                console.log(item.personName.toLowerCase(), lowerCase);
                if (item.personName.toLowerCase().includes(lowerCase)) {
                    console.log(item);
                    dropdownFilteredData.push(item);
                }
            });

            if (dropdownFilteredData.length > 0) {
                setFilteredClientsData([]);
                setFilteredClientsData(dropdownFilteredData);
            } else {
                setFilteredClientsData(clientUserData);
            }
        }
    };

    const onClickComponent = (id) => {
        setIsComponentLoading(id);
    };

    const logout = () => {
        localStorage.clear();
        navigate('/');
    };

    const componentLayout = (progress) => {
        switch (progress) {
            case 1:
                return <ClientListComponent />;
            case 2:
                return <AdvisorListComponent />;
            default:
                break;
        }
    };

    const ClientListComponent = () => {
        return (
            <>
                <DataGrid
                    // rows={filteredClientsData}
                    rows={searchClients}
                    columns={columns}
                    // style={{ height: dynamicHeight }}
                    onCellClick={handleCellClick} // don't select this row after clicking
                    onRowClick={handleRowClick} // don't select this row after clicking
                    components={{ Toolbar: GridToolbar, Row: CustomRow }} // change color of odd even rows
                    isRowSelectable={(params) => params.row.added_queue > 0} // to disabled checkbox
                    // selectionModel={selectionModel}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    // componentsProps={{
                    //     toolbar: {
                    //         showQuickFilter: true,
                    //            quickFilterProps: { debounceMs: 500 },
                    //      },
                    //   }}
                    // loading={true}
                    // autoHeight={true}
                    disableColumnSelector
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'first_name', sort: 'asc' }]
                        }
                    }}
                />
            </>
        );
    };

    const AdvisorListComponent = () => {
        return (
            <>
                <DataGrid
                    rows={filteredAdvisorData}
                    columns={clientsColumns}
                    // style={{ height: dynamicHeight }}
                    onCellClick={handleCellClick} // don't select this row after clicking
                    onRowClick={handleRowClick} // don't select this row after clicking
                    components={{ Toolbar: GridToolbar, Row: CustomRow }} // change color of odd even rows
                    isRowSelectable={(params) => params.row.added_queue > 0} // to disabled checkbox
                    // selectionModel={selectionModel}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    // loading={true}
                    // autoHeight={true}
                    disableColumnSelector
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'first_name', sort: 'asc' }]
                        }
                    }}
                />
            </>
        );
    };

    useEffect(() => {
        fetchUserAllPersonal();
        fetchUserAllPersonalDetails();
    }, []);

    return (
        <>
            <HeaderLayout setIsComponentLoading={setIsComponentLoading} />

            <Grid container spacing={2} style={{ marginTop: '5rem', marginBottom: '1rem' }}>
                <Grid item xs={8} style={{ marginTop: '10px' }}>
                    <Typography className="text-center font20px">{isComponentLoading == 1 ? 'Persons' : ' Clients'}</Typography>
                </Grid>
                {isComponentLoading != 1 ? (
                    <Grid item xs={4}>
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                            <InputLabel id="select-advisor">Advisor</InputLabel>
                            <Select
                                labelId="select-advisor"
                                id="select-advisor"
                                value={selectedAdvisor}
                                label="Advisor"
                                name="advisor"
                                onChange={handleChangeAdvisor}
                            >
                                <MenuItem value={0}>All</MenuItem>
                                {advisorList.map((item, index) => {
                                    return (
                                        <MenuItem key={index} value={item.advisorId}>
                                            {item.advisor}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                ) : (
                    <Grid item xs={4}>
                        <TextField
                            id="search"
                            label="Search..."
                            variant="outlined"
                            size="small"
                            // onChange={handleSearch}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                        ></TextField>
                        {/* <Input placeholder="Placeholder" inputProps={''} /> */}
                    </Grid>
                )}
            </Grid>

            <div style={{ height: '100%', width: '100%' }} className="container">
                <Box
                    sx={{
                        height: 400,
                        width: 1,
                        '& .odd': {
                            bgcolor: '#f5f5f5'
                        },
                        '& .even': {
                            bgcolor: '#eee'
                        }
                    }}
                >
                    {componentLayout(isComponentLoading)}
                </Box>
            </div>
        </>
    );
};

export default AdminDashboard;
