import { useTheme } from '@mui/material/styles';
import logo from 'assets/images/sMLogoBlk.png';

const Logo = () => {
    return (
        <img src={logo} alt="SMI" width="175" />
    );
};

export default Logo;
