// assets
import { IconLetterA } from '@tabler/icons';
// constant
const icons = {
    IconLetterA
};

const AdminMenuItem = {
    id: 'admin',
    // title: 'Admin',
    // caption: 'Admins Panel',
    type: 'group',
    children: [
        {
            id: 'admin',
            title: 'Admin',
            type: 'collapse',
            icon: icons.IconLetterA,
            url: '/admin/partners',
            breadcrumbs: false,

            children: [
                {
                    id: 'eft',
                    title: 'EFT(s)',
                    type: 'item',
                    url: '/ci-efts',
                    target: true
                },
                {
                    id: 'partner',
                    title: 'Partner',
                    type: 'item',
                    url: '/partners',
                    target: true
                },
                {
                    id: 'advisor',
                    title: 'Advisor',
                    type: 'item',
                    url: '/advisors',
                    target: true
                },
                {
                    id: 'fees',
                    title: 'Fees',
                    type: 'item',
                    url: '/fees',
                    target: true
                },
                {
                    id: 'feestype',
                    title: 'Fees Type',
                    type: 'item',
                    url: '/feestypes',
                    target: true
                },
                {
                    id: 'feesrate',
                    title: 'Fees Rate',
                    type: 'item',
                    url: '/feesrates',
                    target: true
                },
                {
                    id: 'faq',
                    title: 'FAQ',
                    type: 'item',
                    url: '/faqs',
                    target: true
                },
                {
                    id: 'referral',
                    title: 'Referral',
                    type: 'item',
                    url: '/referrals',
                    target: true
                },
                {
                    id: 'fundtransfer',
                    title: 'Fund Transfer',
                    type: 'item',
                    url: '/fund_transfer',
                    target: true
                },
                {
                    id: 'smartergroupcompany',
                    title: 'Smarter Group Companies',
                    type: 'item',
                    url: '/smarter_group_company',
                    target: true
                }
            ]
        }
    ]
};

export default AdminMenuItem;
