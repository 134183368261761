import axiosInstance from 'custom-axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTheme } from '@material-ui/core/styles';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import AssetAllocation from 'views/user-components/pages/dashboard/AssetAllocation';
import { numberFormat } from 'hooks/useNumberFormat';

const data = [
    {
        total: 10,
        amt1: 10,
        employer_amount: 0,
        special_amount: 0,
        title: 'Sohan'
    },
    {
        total: 20,
        amt1: 20,
        employer_amount: 0,
        special_amount: 0,
        title: 'Vikram'
    },
    {
        total: 30,
        amt1: 30,
        employer_amount: 0,
        special_amount: 0,
        title: 'Rahul'
    },
    {
        total: 40,
        amt1: 40,
        employer_amount: 0,
        special_amount: 0,
        title: 'Tester'
    }
];

const defaultTotal = {
    total: 0,
    total1: 0,
    total_employer_contribution: 0,
    total_special_contribution: 0
};

const Test = () => {
    const [records, setRecords] = useState(data);
    const [totalObj, setTotalObj] = useState(defaultTotal);

    const handleOnChange = (e, column, index) => {
        let eleValue = Number.parseInt(e.target.value);
        if (Number.isNaN(eleValue)) {
            eleValue = 0;
        }
        const newArray = records.map((item, i) => {
            // console.log(item)
            if (i === index) {
                const rec = records[index];

                let { amt1, employer_amount, special_amount } = rec;

                const value = Number.parseInt(eleValue);

                if (column === 'amt1') {
                    amt1 = value;
                } else if (column === 'employer_amount') {
                    employer_amount = value;
                } else if (column === 'special_amount') {
                    special_amount = value;
                }
                const total = amt1 + employer_amount + special_amount;
                return {
                    ...rec,
                    amt1,
                    employer_amount,
                    special_amount,
                    total
                };
            }
            return item;
        });
        // console.log(newArray)
        setRecords([...newArray]);
    };

    useEffect(() => {
        // console.log("render every time change var")
        const total = records.reduce(
            // console.log(total)
            (total, item) => {
                return {
                    total: total.total + item.total,
                    total1: total.total1 + item.amt1,
                    total_employer_contribution: total.total_employer_contribution + item.employer_amount,
                    total_special_contribution: total.total_special_contribution + item.special_amount
                };
            },
            { total: 0, total1: 0, total_employer_contribution: 0, total_special_contribution: 0 }
        );
        setTotalObj(total);
    }, [records]);

    const handleSubmit = () => {
        alert(JSON.stringify(records));
    };

    return (
        <>
            <div className="App">
                <table>
                    <thead>
                        <th>index</th>
                        <th>title</th>
                        <th>total</th>
                        <th>amt 1</th>
                        <th>Total Employer Contribution</th>
                        <th>Total Special  Contribution</th>
                    </thead>
                    <tbody>
                        {records.map((item, index) => {
                            return (
                                <tr>
                                    <td>{index + 1}</td>
                                    <td>{item.title}</td>
                                    <td>{item.total}</td>
                                    <td>
                                        <input
                                            type="text"
                                            id={`amt1 ${index}`}
                                            value={item.amt1}
                                            onChange={(e) => handleOnChange(e, 'amt1', index)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            id={`employer_amount ${index}`}
                                            value={item.employer_amount}
                                            onChange={(e) => handleOnChange(e, 'employer_amount', index)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            id={`special_amount ${index}`}
                                            value={item.special_amount}
                                            onChange={(e) => handleOnChange(e, 'special_amount', index)}
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td></td>
                            <td></td>
                            <td>Total : {totalObj.total}</td>
                            <td> total1 : {totalObj.total1}</td>
                            <td>Total Employer Contribution : {totalObj.total_employer_contribution}</td>
                            <td>Total Special Contribution : {totalObj.total_special_contribution}</td>
                            </tr>
                    </tfoot>
                </table>
                <br />
                <br />
                <br />
                <button onClick={handleSubmit}>Submit</button>
            </div>
        </>
    );
};

export default Test;
