import { IconLetterC } from '@tabler/icons';
// constant
const icons = { IconLetterC };

const ClientMenuItem = {
    id: 'client',
    // title: 'Admin',
    // caption: 'Admins Panel',
    type: 'group',
    children: [
        {
            id: 'client',
            title: 'Client',
            type: 'collapse',
            icon: icons.IconLetterC,
            url: '/admin/register_users',
            breadcrumbs: false,
            children: [
                {
                    id: 'registeruser',
                    title: 'Users',
                    type: 'item',
                    url: '/register_users',
                    target: true
                },
                {
                    id: 'useraccount',
                    title: 'User Account',
                    type: 'item',
                    url: '/user_account',
                    target: true
                }
            ]
        }
    ]
};

export default ClientMenuItem;
