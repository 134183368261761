import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import ProfileFinalStep from 'views/user-components/pages/signup-profile/ProfileFinalStep';
import Test from 'views/admin-components/pages/Test';

// login option 3 routing
const AdminLogin = Loadable(lazy(() => import('views/admin-components/pages/authentication/AdminLogin')));
const CompanyLogin = Loadable(lazy(() => import('views/admin-components/company/CompanyLogin')));
const SignUp = Loadable(lazy(() => import('views/user-components/pages/signup/SignUpSteps')));
const SignIn = Loadable(lazy(() => import('views/user-components/pages/signin/SignIn')));
const ActivateAccount = Loadable(lazy(() => import('views/user-components/pages/ActivateAccount')));
const SmartGroupActivation = Loadable(lazy(() => import('views/user-components/pages/company-register/SmartGroupActivation')));
const SmartGroupRegister = Loadable(lazy(() => import('views/user-components/pages/company-register/SmartGroupRegister')));
const DashboardDefault = Loadable(lazy(() => import('views/admin-components/dashboard/Default/TotalIncomeDarkCard')));
const LandingPage = Loadable(lazy(() => import('views/user-components/pages/LandingPage/LandingPage')));
const SmartGroup = Loadable(lazy(() => import('views/user-components/pages/company-register/SmartGroup')));
const IconLandingPage = Loadable(lazy(() => import('views/landing-pages/Icon/Icon')));
const RainTreeLandingPage = Loadable(lazy(() => import('views/landing-pages/rain-tree/RainTree')));
const WealthPortLandingPage = Loadable(lazy(() => import('views/landing-pages/wealthport/WealthPort')));


// TODO: CREATE A CONDITION FOR TO CHECK ADMIN/USED LOGIN AND REDIRECT THEM TO SPECIFIC PAGE.
// TODO: IF USER LOGIN  THEN CHECK isUserLogin

const isAdminLogin = localStorage.getItem('isAdminLogin');
const isUserLogin = localStorage.getItem('isUserLogin');
const PublicRoute = {
    path: '/',
    element: <MinimalLayout />,
    // element: isAdminLogin ? <Navigate to={'/admin/assetcategories'} /> : <MinimalLayout />,
    // element: <LandingPage />,
    children: [
        {
            path: '/',
            element: <LandingPage />
        },
        {
            path: '/admin',
            element: isAdminLogin ? <Navigate to={'/admin/assetcategories'} /> : <AdminLogin />
        },
        {
            path: '/:partnerUrl/signup',
            element: isAdminLogin ? <Navigate to={'/admin'} /> : <SignUp />
        },
        {
            path: '/:partnerUrl/activateaccount',
            element: isAdminLogin ? <Navigate to={'/admin'} /> : <ActivateAccount />
        },
        {
            path: '/:partnerUrl/signin',
            element: isUserLogin ? <Navigate to={'/signup/profile'} /> : <SignIn />
        },
        { path: '/smarter_group_activation', element: <SmartGroupActivation /> }, //NAVIGATE({ TO, REPLACE, STATE }
        { path: '/smart_group', element: <SmartGroup /> },  
        { path: '/test', element: <Test /> },
        { path: '/company_login', element: <CompanyLogin /> },


        { path: '/icon', element: <IconLandingPage /> },
        { path: '/raintree', element: <RainTreeLandingPage /> },
        { path: '/wealthport', element: <WealthPortLandingPage /> },

        // { path: '*', element: isUserLogin ? <DashboardDefault /> : <Navigate to={'/admin'} /> }, // NOT FOUND  PAGE
    ]
};
export default PublicRoute;
