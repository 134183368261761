import HeaderLayout from 'layout/HeaderLayout';
import React from 'react';
import './signup-profile/profile.css';

const ThankYouPage = () => {
    document.title = 'Smart Money: Thank You';
    return (
        <>
           <HeaderLayout/>
            <section className="bg-light successfully">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 text-center">
                            <img className="img-fluid" src="/images/img-01.png" alt="thankyou logo" style={{maxWidth: '55%'}} />
                            <div className="bg-success-01 p-3 mt-5">
                                <h5 className="mb-0 text-success">
                                    <i className="fa-solid fa-check-to-slot me-2" /> Account sohantandel@yahoo.com has been successfully
                                    activated.
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ThankYouPage;
