import dashboard from './dashboard';
import pages from './pages';
import utilities from './utilities';
import other from './other';
import PortfolioMenuItem from './PortfolioMenuItem';
import ReblancingMenuItem from './ReblancingMenuItem';
import AdminMenuItem from './AdminMenuItem';
import NewsletterMenuItem from './NewsletterMenuItem';
import ClientMenuItem from './ClientMenuItem';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [PortfolioMenuItem, ReblancingMenuItem, AdminMenuItem, ClientMenuItem, NewsletterMenuItem]
};

export default menuItems;
