import { numberFormat } from 'hooks/useNumberFormat'
import React from 'react'
import ReactApexChart from 'react-apexcharts'
import { colorArray } from 'store/constant'

const DonutChart = (props) => {
    return (
        <>
        <ReactApexChart
             type="donut"
             // width={450}
             height={350}
             series={props.seriesValue}
             options={{
                 legend: {
                     show: true,
                     position: 'bottom'
                 },
                 // show in donut grpah
                 dataLabels: {
                     enabled: false
                 },
                 tooltip: {
                     y: {
                         formatter: function (value) {
                             return numberFormat(value);
                         }
                     }
                 },
                 noData: { text: 'Loading graph...' },
                 // colors: ['#41a3b0', '#4c5c71'],
                 colors:colorArray,

                 plotOptions: {
                     pie: {
                         startAngle: 0,
                         endAngle: 360,
                         expandOnClick: true,
                         offsetX: 0,
                         offsetY: 0,
                         customScale: 1,
                         dataLabels: {
                             offset: 0,
                             minAngleToShowLabel: 10
                         },
                         donut: {
                             size: '70%', // change the thickness og graph
                             background: 'transparent',
                             labels: {
                                 show: true,
                                 name: {
                                     show: true,
                                     fontSize: '15px',
                                     fontFamily: 'Helvetica, Arial, sans-serif',
                                     fontWeight: 600,
                                     color: '#454B1B',
                                     offsetY: -10,
                                     formatter: function (val) {
                                         return val;
                                     }
                                 },
                                 value: {
                                     show: true,
                                     fontSize: '15px',
                                     fontFamily: 'Helvetica, Arial, sans-serif',
                                     fontWeight: 400,
                                     // color: "#FF0000",
                                     color: undefined,
                                     offsetY: 16,
                                     formatter: function (val) {
                                         return numberFormat(val);
                                     }
                                 },
                                 total: {
                                     show: true,
                                     showAlways: true, // if it is true then total in the middle always show.
                                     label: 'Total',
                                     fontSize: '18px',
                                     fontFamily: 'Helvetica, Arial, sans-serif',
                                     fontWeight: 600,
                                     color: '#373d3f',
                                     formatter: function () {
                                         return numberFormat(props.total);
                                     }
                                 }
                             }
                         }
                     }
                 },
                 labels: props.labelTitle
             }}
        />
        </>
    )
}

export default DonutChart