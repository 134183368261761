import React from 'react';

const Footer = () => {
    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    {/* <div className="footer">
                        <div className="footer-top">
                            <div className="row">
                                <div className="col-xl-12 col-lg-6">
                                    <div className="footer-widget">
                                        <div className="footer-logo">
                                            <a href="#"><img className="img-fluid" src="images/logo-light.png" /></a>
                                        </div>
                                        <p>Past performance is no guarantee of future result. Any historical returns or expected returns may not reflect actual future performance. All securities involve risk and may result in loss. Read our Investment Risk Disclosure.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="footer-bottom">
                        <div className="container">
                            <div className="inner-content">
                                <div className="row align-items-center">
                                    <div className="col-xl-6 col-12 text-xl-start text-center">
                                        <div className="copyright">
                                            <p>Ⓒ Copyright 2020 Smart Money Capital Management Inc.</p>
                                        </div>
                                    </div>
                                    <div className="col-xl-5 col-12 text-xl-end text-center mt-3 mt-xl-0">
                                        <ul className="socila">
                                            {/* {'/images/check.jpg'} */}
                                            <li>
                                                <a href="#">
                                                    <img src={'/images/social-01.png'} />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <img src={'/images/social-02.png'} />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <img src={'/images/social-03.png'} />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <img src={'/images/social-04.png'} />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Footer;
